/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap"); */
@import "leaflet/dist/leaflet.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "swiper/swiper-bundle.css";
@import "video.js/dist/video-js.css";
@import "weather-icons/css/weather-icons.css";

@import "animate.css";

@import "animate.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

span.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
  opacity: 1 !important;
}

:root.ios {
  --ion-safe-area-bottom: 20px !important;
  --ion-safe-area-top: 1.5rem;
}
/* Color codes */
:root {
  --brand-deep-seablue: #00005c;
  --brand-sky-blue: #8bcce1;
  --brand-sky-blue-lighter: #c4e4ee;
  --brand-sky-blue-light: #e8f5f9;
  --brand-sunset-orange: #fc744a;
  --brand-sunset-orange-light: #fee3db;
  --brand-nautical-red: #fc1c3a;
  --dark-color: #000000;
  --white: #ffffff;
  --black: #000000;
  --ds-success: #00ac31;
  --ds-success-light: #ebf9ef;
  --ds-error: #e42727;
  --ds-error-light: #fdeeee;
  --ds-warning: #ffc42e;
  --ds-warning-light: #fff6de;
  --ds-white: #ffffff;

  --border-width: 1px;
  --ion-safe-area-bottom: 20px !important;
  --ion-safe-area-top: 0.5rem;

  --success: #004ac31;
  --danger: #e42727;
  --success-color: var(--ds-success);
}

ion-header > ion-toolbar.ios:first-of-type {
  /* padding-top: var(--ion-safe-area-top, 0) !important; */
  padding-top: 34px !important;
}

ion-toolbar {
  --background: var(--white);
}
ion-header ion-toolbar {
  --background: var(--brand-deep-seablue);
}

ion-header ion-toolbar.bg-clear {
  --background: transparent;
}

/* ion-header ion-toolbar:first-of-type {
    padding-top: 20px;
} */

@media screen and (max-width: 768px) {
  ion-header.spacer {
    padding-top: 24px;
  }
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  display: flex !important;
  align-items: center !important;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) h2 {
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) div {
  display: flex !important;
  align-items: center !important;
}
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) div h2 {
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.voice-icon {
  display: inline-block;
  width: 20px; /* Adjust the width and height as needed */
  height: 20px;
  background-image: url("assets/icons/voice-icon.svg");
  background-size: cover;
  /* Add more styles as needed */
}

ion-spinner {
  --color: var(--brand-deep-seablue);
}

/* Global primary styles */
.border-primary {
  border-width: 2px;
  border-color: var(--brand-deep-seablue);
}

.cdk-overlay-backdrop {
  z-index: 1 !important;
}
.toast-wrapper .toast-bottom {
  bottom: 40px !important;
}
.toast-bottom {
  bottom: 40px !important;
}
.bg-primary {
  background-color: #00005c !important;
}

.bg-btn-disabled {
  background-color: #bcbac4 !important;
}

.text-primary {
  color: #00005c;
}

.border-success {
  border-width: var(--border-width);
  border-color: var(--success-color);
}

.bg-success {
  background-color: var(--success-color);
}

.text-success {
  color: var(--success-color);
}

.headerColor {
  background-color: #ffffff;
}
.header-large {
  font-size: 32px;
}

.header-large-bold {
  font-size: 32px;
  font-weight: 800;
}

ion-content::-webkit-scrollbar {
  display: none;
}

ion-content {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  --keyboard-offset: 0px !important;
}

/*.swiper-pagination-bullet {*/
/*    background-color: grey !important;*/

/*}*/

.universal_width {
  max-width: 650px;
  margin: 0px auto;
}

.starting-gradient {
  background: rgb(0, 0, 92);
  background: linear-gradient(9deg, rgba(0, 0, 92, 1) 8%, rgba(0, 0, 92, 0) 60%);
}

/* Custome @ayers and @apply */
@layer components {
  /* Typography */
  .ds-display-heading-2xl {
    @apply text-[72px] font-bold text-black capitalize mb-4 md:mb-1 leading-normal;
  }

  .starting-swiper-pagination {
    @apply !relative !top-auto !bottom-auto;
  }

  .ds-display-heading-xl {
    @apply text-[54px] font-bold text-black capitalize mb-4 md:mb-1 leading-normal;
  }

  .ds-heading-2xl {
    @apply text-[48px] font-bold text-black capitalize mb-4 md:mb-1 leading-normal;
  }

  .ds-heading-xl {
    @apply text-[34px] font-bold text-black capitalize mb-3 md:mb-1 leading-normal;
  }

  .ds-heading-l {
    @apply text-[28px] font-bold text-black capitalize mb-2 md:mb-1 leading-normal;
  }

  .ds-heading-m {
    @apply text-[24px] font-bold text-black capitalize mb-1 md:mb-1 leading-normal;
  }

  .ds-heading-s {
    @apply text-[20px] font-bold text-black capitalize mb-1 md:mb-1 leading-normal;
  }

  .ds-eyebrow {
    @apply text-[14px] font-bold text-black capitalize mb-0 leading-normal;
  }

  .page-nav-chips {
    @apply px-4 py-1 text-lg font-semibold text-brandDeepSeaBlue rounded-3xl border-none hover:bg-brandSkyBlue hover:text-brandDeepSeaBlue focus:z-10 focus:ring-2 focus:bg-brandSkyBlue focus:text-brandDeepSeaBlue;
  }

  .dialog-modal {
    @apply flex flex-wrap content-center w-full h-full;
  }

  .dialog-modal-inner {
    @apply flex justify-center w-full h-full;
  }

  .dialog-modal-content {
    @apply w-full md:w-4/5 lg:w-3/5 2xl:w-2/5 bg-white rounded-lg mb-5 overflow-y-scroll shadow-dsL;
  }

  .data-grid {
    @apply grid grid-cols-2 sm:grid-cols-2 justify-between items-start text-left mt-8 p-2 gap-4;
  }

  .data-grid--item-title {
    @apply uppercase text-sm sm:text-sm text-gray-800 mb-1;
  }

  .data-grid--item--data {
    @apply capitalize text-sm sm:text-base font-semibold text-black;
  }
  .primary-button {
    @apply bg-deepSeablue w-full rounded-full text-white py-3 text-lg;
  }
  .sunset-button {
    @apply bg-brandSunsetOrange w-full rounded-full text-white py-3 text-lg;
  }
  .diabled-button {
    @apply bg-buttonDisabledColor w-full rounded-full text-white py-3 text-lg;
  }
  .black-button {
    @apply bg-buttonBlackColor w-full rounded-full text-white py-3 text-lg;
  }
  .cancel-button {
    @apply bg-buttonCancelColor w-full rounded-full text-white py-3 text-lg;
  }

  .more-menu-btn {
    @apply relative flex gap-3 items-center justify-between p-4 text-sm font-medium text-center text-white cursor-pointer border-2 border-solid border-brandDeepSeaBlue w-full rounded-lg;
  }

  .main-menu-row {
    @apply relative flex gap-3 items-center justify-between px-4 py-5 text-sm font-medium text-center text-black cursor-pointer border-solid w-full;
  }

  .onboarding-title {
    @apply text-dsxl font-extrabold m-0;
  }

  .onboarding-description {
    @apply space-y-1 text-base text-dsGray70 font-medium mt-1;
  }

  /* Dashboard Slides */
  .dashboard-slide {
    @apply bg-brandSunsetOrange  rounded-xl text-white text-base font-medium;
  }

  /* Form Control */
  .bb-formcontrol-error {
    @apply text-brandNauticalRed text-sm ml-1 pt-1;
  }

  .captainai-action {
    @apply p-4 rounded-xl bg-white border border-solid border-[#8BCCE1];
  }

  .captainai-action-wrapper {
    background: linear-gradient(133deg, rgba(255, 255, 255, 0.54) 17.71%, rgba(73, 113, 255, 0.05) 103.39%);
    backdrop-filter: blur(3.0999999046325684px);
  }
}

.warning {
  @apply bg-yellow-100 text-yellow-700 border border-yellow-300;
}

.danger {
  @apply bg-red-100 text-red-700 border border-red-300;
}

.success {
  @apply bg-green-100 text-green-700 border border-green-300;
}

.default {
  @apply bg-gray-100 text-gray-700 border border-gray-300;
}

.primary {
  @apply bg-sky-100 text-sky-500;
}

.status-gray {
  @apply bg-gray-100 text-gray-700;
}

.status-blue {
  @apply bg-brandSkyBlueLight text-brandDeepSeaBlue;
}

.status-green {
  @apply bg-dsSuccessLight text-dsSuccess;
}

.status-active-green {
  @apply bg-dsSuccess text-white;
}

.status-active-red {
  @apply bg-dsError text-white;
}
.status-active-blue {
  @apply bg-deepSeablue text-white;
}
.status-red {
  @apply bg-dsErrorLight text-dsError;
}

.status-yellow {
  @apply bg-dsWarning text-black;
}

.status-yellow-light {
  @apply bg-dsWarningLight text-black;
}

.status-orange {
  @apply bg-brandSunsetOrange text-white;
}

.status-pill {
  @apply px-2 py-1 rounded-full text-xs font-medium;
}

/* ion-header {
    margin-top: 20px !important;
} */

html,
body {
  /* font-family: 'Montserrat', sans-serif !Important; */
  font-family: SF Pro Text, sans-serif;
  outline: none !important;
  -webkit-text-size-adjust: 100% !important;
}

.ng-image-slider {
  height: 40vh !important;
}

.ng-image-fullscreen-view .lightbox-wrapper .close {
  top: 60px !important;
  right: 20px !important;
}

ion-modal::part(handle) {
  top: 16px;
}

ion-modal.notification-modal::part(content) {
  background: transparent;
}
ion-modal.notification-modal {
  z-index: 100000 !important;
}

/* AI ALERT */
ion-modal.ai-alerts-modal {
  --border-radius: 0px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background: rgb(1 12 28 / 43%);
}
ion-modal.ai-alerts-modal::part(content) {
  height: fit-content;
  width: 95%;
  border-radius: 12px;
}
ion-modal.ai-provider-modal {
  padding: 20px 16px;
}
ion-modal.ai-provider-modal::part(content) {
  padding: 8px;
  width: 95%;
  height: fit-content;
  border-radius: 12px;
}

ion-button#top-center {
  transition: none !important;
  height: auto !important;
}

.main {
  height: 40vh !important;
}

.button-color {
  background: rgb(0, 136, 227);
  background: linear-gradient(180deg, rgba(0, 136, 227, 1) 0%, rgba(0, 137, 208, 1) 42%, rgba(1, 71, 107, 1) 100%);
}

.otp-border-radius {
  border-radius: 10px !important;
}

ion-tab-button {
  --color-selected: black;
  --color: grey;
  --padding-start: 2px;
  --padding-end: 2px;
}

.main-modals {
  --height: 100vh !important;
  --width: 100vw !important;
}

#app {
  background-color: transparent !important;
}

.invisible {
  color: transparent !important;
  background-color: transparent !important;
}

.tabs .tab-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.img-scale {
  width: 1.5rem !important;
}

.button-color {
  background: rgb(0, 136, 227);
  background: linear-gradient(180deg, rgba(0, 136, 227, 1) 0%, rgba(0, 137, 208, 1) 42%, rgba(1, 71, 107, 1) 100%);
}

.open_order_card_cover_bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15) 25%, rgba(0, 0, 0, 0) 100%);
}

.bg_blue_radial {
  background: radial-gradient(circle, rgba(0, 168, 255, 1) 0%, rgba(0, 137, 208, 1) 50%, rgba(0, 107, 162, 1) 100%);
}

.bg_black_radial {
  background: radial-gradient(
    circle,
    rgba(175, 175, 175, 1) 0%,
    rgba(116, 116, 116, 1) 50%,
    rgba(104, 104, 104, 1) 100%
  );
}

.bg_orange_radial {
  background: radial-gradient(circle, rgba(247, 183, 51, 1) 0%, rgba(255, 162, 0, 1) 100%);
}

.bg_white_linear {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.78) 40%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.custom-popup,
.leaflet-popup-content-wrapper {
  border: red !important;
}

.leaflet-popup {
  position: absolute;
  text-align: center;
}

.custom-popup,
.leaflet-popup-tip {
}

.leaflet-popup-content {
  margin: 0;
}

/* Fullcalendar */

.fc .fc-toolbar-title {
  font-size: 1rem;
  margin-bottom: 6px;
  text-align: right;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  width: 50%;
}
/*.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group .fc-button {*/
/*    border-top-left-radius: 0.375rem;*/
/*    border-bottom-left-radius: 0.375rem*/
/*}*/
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group {
  width: 100%;
}
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group .fc-button {
  background: #ffffff;
  color: #00005c;
  border: 2px solid #00005c;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group .fc-button.fc-button-active {
  background: #00005c;
  font-weight: 500;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
  gap: 6px;
}

.fc .fc-toolbar-title {
  width: 100%;
}

.fc .fc-toolbar-chunk:nth-child(3) .fc-button {
  width: 50%;
}

.fc .fc-button-group > .fc-button {
  flex-shrink: 0;
}

.fc .fc-toolbar-chunk:nth-child(3) button {
  color: white;
}

.fc .fc-header-toolbar {
  gap: 2rem;
  align-items: flex-end;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button {
  width: 42px;
  height: 42px;
  border-radius: 50% !important;
  border: 2px solid #00005c;
  background: #ffffff;
  color: var(--brand-deep-seablue) !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button {
  width: 42px;
  height: 42px;
  border-radius: 50% !important;
  border: 2px solid #00005c;
  background: #ffffff;
  color: var(--brand-deep-seablue) !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
  border-color: #000046 !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button:enabled:active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button:enabled:hover {
  background-color: var(--white) !important;
  border-color: var(--brand-deep-seablue);
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button:enabled:active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button:enabled:hover {
  background-color: var(--white) !important;
  border-color: var(--brand-deep-seablue);
}

.fc .fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button {
  background: var(--brand-deep-seablue);
  border: 1px solid var(--brand-deep-seablue);
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  background: #00005c;
}

.fc.fc-theme-standard .fc-toolbar .fc-prev-button {
  background: #00005c;
  font-size: 1rem;
}

.fc .fc-list-empty-cushion {
  color: var(--brand-deep-seablue) !important;
  font-weight: 600 !important;
}
.event-dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  margin: 0 auto !important;
  background-color: #fc7449 !important;
}
.fc-theme-standard .fc-list {
  background: var(--brand-sky-blue-light) !important;
  border: 1px solid #00005c !important;
  border-radius: 16px;
  overflow: hidden;
}

.fc.fc-theme-standard .fc-toolbar .fc-next-button {
  background: #00005c;
  font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-listWeek-button {
  background: gray !important;
  width: 50%;
  font-size: 1rem;
  font-weight: bolder;
  text-align: center !important;
  /*width: 5px !important;*/
}

.fc.fc-theme-standard .fc-toolbar .fc-dayGridMonth-button {
  width: 50%;
  font-size: 1rem;
  font-weight: bolder;
  text-align: center !important;
  align-content: center;
  margin-left: 1px !important;
  /*width: 5px !important;*/
}

.fc.fc-theme-standard .fc-view-harness td {
  border: none !important;
  border-bottom: 1px solid #e7e7e7 !important;
}

.fc .fc-toolbar-chunk:nth-child(3) button:nth-child(1):after {
  font-size: 12px !important;
  text-align: center !important;
  /*content: 'Calendar' !important;*/
}

.fc .fc-toolbar-chunk:nth-child(3) button:nth-child(2):after {
  font-size: 12px !important;
  text-align: center !important;
}

.fc .fc-timeline .fc-button-group > .fc-button {
  background-color: #ffffff;
  color: #999999;
  border-radius: 1.5em;
  text-transform: uppercase;
  border-color: #b9b9b9;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center !important;
}

.fc .fc-timeline .fc-button-group > .fc-button.fc-button-active {
  background-color: var(--brand-sky-blue-light);
  color: #ffffff;
  border-color: #b9b9b9;
  text-align: center !important;
}

.fc .fc-timeline .fc-timeline-event .fc-event-main > div {
  border-radius: 50px;
  padding: 5px 30px;
  overflow: hidden;
  color: #333333;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center !important;
}

.fc-day-today {
  background-color: var(--brand-sky-blue-light) !important;
}
.fc-day-today .fc-theme-standard a {
  color: var(--ds-white) !important;
}

.fc.fc-theme-standard .fc-view-harness .fc-dayGridMonth-view .fc-event.fc-daygrid-block-event {
  /* background-color: var(--brand-sunset-orange) !important;
    width: 8px;
    height: 8px;
    border-radius: 50%; */
  width: 100%;
  background-color: transparent !important;
  border: none !important;
}

.fc .fc-daygrid-event-harness {
  display: flex;
  justify-content: center;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
  font-weight: 600;
}

.fc.fc-theme-standard .fc-view-harness th {
  border: none !important;
  border-bottom: 1px solid #e7e7e7 !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 1.4em;
}

.fc .fc-list-event-time {
  display: none !important;
}

.fc .fc-list-empty-cushion {
  width: 100% !important;
  margin: 0;
}

.fc .empty-calendar-wrapper {
  width: 100% !important;
  margin: 0;
}

.fc .empty-calendar-wrapper {
  width: 100% !important;
  margin: 0;
}

.empty-calendar-header {
  color: var(--black);
  background: #f8f9fa;
  font-weight: 700;
}

.fc .empty-calendar-header {
  margin: 0 !important;
  width: 100% !important;
}

.fc .empty-calendar-content {
  margin: 5em auto !important;
  text-align: center;
}

.search-drop-container {
  background: white !important;
  background: black;
  z-index: 10000 !important;
  box-shadow: 0px 7px 9px 1px #80808087;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* ion-toast {
    margin-top: 50px;
} */

ion-toast.toast-custom-class {
  --box-shadow: 0 4px 2px 2px rgba(0, 0, 0, 0.2);
  --background: var(--brand-sky-blue-light);
  --color: var(--brand-deep-seablue);
  --font-weight: 500;
  --border-radius: 6px;
  padding: 10px 20px !important;
  font-size: 16px;
  font-weight: 500;
}

ion-toast.toast-custom-class.toast-danger {
  --background: var(--ds-error);
  --color: #ffffff;
}

ion-toast.toast-custom-class.toast-danger::part(button) {
  color: #ffffff;
}

ion-toast.toast-custom-class.toast-success {
  --background: var(--ds-success);
  --color: #ffffff;
}

ion-toast.toast-custom-class.toast-success::part(button) {
  color: #ffffff;
}

ion-toast.app-alert-toast {
  --background: var(--white);
  --color: var(--black);
}

.toast-custom-class .toast-message {
  --font-weight: 500;
}

.img-div {
  /*width: 99vw !important;*/
  display: inline-block;
}

.main-inner {
  margin-left: 0px;
  /*width: 1000px !important;*/
  transition: all 1s ease-in-out 0s;
  overflow: scroll;
}

.lable {
  font-size: 16px;
  font-weight: bold;
}

ion-item.remove-left {
  margin-right: -17px;
}

.text-black {
  color: #000 !important;
}

.remove-close-icon-margin-left {
  margin-right: -10px !important;
}

input::placeholder {
  color: #6a7380 !important;
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img,
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
  object-fit: cover !important;
}

.arrow-back {
  font-size: 30px;
  /* margin-left: -10px; */
}

#background-content {
  background: transparent !important;
}

.disabled-button-color {
  background-color: #bcbac4;
}

.percentage-text {
  font-size: 15px;
  font-weight: 500;
  color: #73717f;
  font-family: "SF Pro Text", sans-serif;
}

#onboarding {
  font-family: "SF Pro Text", sans-serif;
}

/* #onboarding input {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 16px;
    line-height: 24px;
}

#onboarding input:focus {
    border: 2px solid var(--brand-deep-seablue);
}

#onboarding input + label,
#onboarding textarea + label {
    color: #73717f;
} */

/* on peer focus label change */
/* #onboarding input:focus + label {
    font-weight: 600;
    color: var(--brand-deep-seablue);
    line-height: 20px;
} */

#onboarding pi-steps {
  padding: 0 16px;
  display: block;
}

#onboarding pi-steps pi-button[size="large"] button {
  color: var(--brand-deep-seablue);
  background-color: #e8f5f9;
}

#onboarding pi-steps pi-button[size="large"] button {
  border-radius: 26px;
  background: var(--primary-colors-deep-sea-blue, #00005c);
  padding: 18px 16px !important;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  height: 54px;
}

#onboarding pi-button[size="large"] button {
  border-radius: 26px;
  background: var(--primary-colors-deep-sea-blue, #00005c);
  padding: 18px 16px !important;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  height: 54px;
}

#onboarding pi-steps hr {
  border-color: var(--ds-success-light);
  background-color: var(--ds-success-light);
}

#onboarding pi-steps hr.border-blue-500 {
  border-color: var(--ds-success);
  background-color: var(--ds-success);
}

#onboarding pi-steps span {
  font-size: 14px;
  font-weight: 600;
  color: var(--brand-deep-seablue);
}

app-confirm-dailog .black-button {
  background: var(--primary-colors-deep-sea-blue, #00005c);
  padding: 16px 16px !important;
  font-size: 18px;
  font-weight: 600;
  border-radius: 32px;
}

app-confirm-dailog .cancel-button {
  background: var(--brand-sky-blue-light, #8bcce1);
  color: var(--brand-deep-seablue, #00005c);
  padding: 16px 16px !important;
  font-size: 18px;
  font-weight: 600;
  border-radius: 32px;
}

/* pi-image-picker > div {
    background-color: #e8f5f9;
    border: 1px dashed #00005c;
} */

pi-select-list i {
  color: var(--brand-deep-seablue) !important;
  font-size: 20px !important;
}

pi-search-box i {
  color: var(--brand-deep-seablue) !important;
  font-size: 20px !important;
}

#onboarding .toggle {
  height: 100%;
}

#onboarding .toggle button {
  height: 100%;
  height: 60px;
  font-size: 14px;
}

#onboarding pi-steps button span {
  color: #fff;
}

.custom-marker[_ngcontent-vdd-c197] {
  background-color: #00005c !important;
}

.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
  width: 60px !important;
}

.document-input input {
  margin-right: 0 !important ;
  width: 100% !important;
}

ion-popover.default-popover {
  --background: var(--brand-deep-seablue);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 300px;
}

ion-popover.notice-popover {
  --width: 90vw;
  --max-width: 400px;
  --backdrop-opacity: 0.3;
  --box-shadow: none;
}

ion-popover.notice-popover::part(content) {
  border-radius: 20px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

ion-popover.popover-guide {
  --width: 80vw;
  --max-width: 400px;
  --backdrop-opacity: 0.1;
  --box-shadow: none;
}

ion-popover.popover-guide ion-content::part(background) {
  background-color: var(--brand-deep-seablue);
}

ion-popover.popover-guide::part(arrow)::after {
  background: var(--brand-deep-seablue);
}

ion-popover.popover-guide-welcome {
  --width: 80vw;
  --max-width: 400px;
  --backdrop-opacity: 0.5;
  --box-shadow: none;
}

ion-popover.popover-guide-welcome::part(content) {
  border-radius: 20px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

ion-popover.default-popover ion-content {
  --background: var(--brand-deep-seablue);
  --color: white;
}

ion-popover.popover-prompt::part(content) {
  background-color: var(--brand-deep-seablue);
  --color: white;
}

ion-popover.default-popover::part(backdrop) {
  background-color: transparent;
}

ion-button {
  --background: rgba(40, 173, 218, 0);
  --box-shadow: unset;
}

ion-action-sheet.attention .action-sheet-button:not(.danger),
ion-action-sheet.attention .action-sheet-button.ion-focused:not(.danger) {
  color: #000000;
}

.dashboard-swiper {
  position: relative !important;
}

.dashboard-swiper .swiper-pagination-bullet {
  background-color: var(--brand-sunset-orange-light);
}

.dashboard-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--brand-sunset-orange) !important;
}

.image-viewer .swiper-slide {
  width: fit-content;
}

.cropper-wrapper image-cropper > div {
  background-color: transparent;
}

.cropper-wrapper image-cropper > div > img {
  margin: 0 auto;
}

.cropper-wrapper image-cropper .ngx-ic-overlay {
  outline: none !important;
}

.cropper-wrapper image-cropper .ngx-ic-square {
  background: #ffffff !important;
}

.pac-logo:after {
  display: none !important;
}

/* Temporary fix for the pac-container modal conflict */
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9; /* Change to border-top */
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  transition: top 0.3s ease-in-out;
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
}

.pac-item {
  padding: 6px 8px;
  font-size: 14px;
}

/* TODO: Replace this with final checklist styling implementation */
#view-service-order-checklist .pr-4.flex.space-x-2 {
  display: none;
}

#view-service-order-checklist check-category > div {
  background-color: white;
  border-bottom: 1px solid #8bcce1;
  font-size: 14px;
  padding: 0 0 12px 8px;
}

#view-service-order-checklist pi-check-box h1 {
  font-size: 16px;
  font-weight: 600;
  color: #00005c;
}

/* Masks */
.standard-mask {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
}

.header-mask {
  width: 100%;
  height: 300px;
}

.header-mask img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask: url(assets/images/masks/header-mask.svg);
  mask: url(assets/images/masks/header-mask.svg);
}

/* Animation - Started 040823 */
/* TODO: Replace this temporary animation with angular or ionic animations */
.bbanimate-fast {
  transition: all 0.2s ease-in-out;
}

.bbanimate-slow {
  transition: all 0.5s ease-in-out;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.fc-more-link {
  display: none !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 4px !important;
}

/* #newFormCheckListManager {
    margin-top: 48px;
} */

/* Dropdown Select */
.bb-floating-label {
  transition: 0.3s ease all; /* smooth transition */
  left: 0;
  top: 0;
  position: relative;
  font-weight: 500;
  color: #73717f;
}

.bb-input-floating-label {
  transition: 0.3s ease all; /* smooth transition */
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  font-weight: 500;
  color: #73717f;
}

.bb-floating-label.float,
.bb-input-floating-label.float {
  position: absolute;
  top: -8px; /* Adjust this to desired position above the textarea */
  transform: translateY(0);
  left: 10px;
  font-size: 0.75rem; /* you can change the font size when the label is floating */
  background-color: #fff;
  padding: 0 2px;
}

pi-input > div > div.overflow-hidden {
  overflow: visible !important;
}

pi-text-area i.fa-microphone {
  font-size: 18px !important;
  color: var(--brand-deep-seablue) !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 28, 58, 0.9);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(252, 28, 58, 0.5), 0 0 0 0 rgba(252, 28, 58, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(252, 28, 58, 0.9);
  }
}

.pulse-box {
  animation: pulse 1.5s infinite;
}

.ClipPath {
  -webkit-clip-path: polygon(
    7% 81%,
    0 82%,
    0 0,
    100% 0,
    100% 94%,
    90% 96%,
    78% 97%,
    68% 96%,
    60% 94%,
    43% 89%,
    25% 84%,
    17% 82%
  );
  clip-path: polygon(
    7% 81%,
    0 82%,
    0 0,
    100% 0,
    100% 94%,
    90% 96%,
    78% 97%,
    68% 96%,
    60% 94%,
    43% 89%,
    25% 84%,
    17% 82%
  );
}

.clippath-reverse {
  -webkit-clip-path: polygon(
    93% 81%,
    100% 82%,
    100% 0,
    0 0,
    0 94%,
    10% 96%,
    22% 97%,
    32% 96%,
    40% 94%,
    57% 89%,
    75% 84%,
    83% 82%
  );
  clip-path: polygon(
    93% 81%,
    100% 82%,
    100% 0,
    0 0,
    0 94%,
    10% 96%,
    22% 97%,
    32% 96%,
    40% 94%,
    57% 89%,
    75% 84%,
    83% 82%
  );
}

.unstyled-item {
  --border-style: none;
  --background: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --inner-padding-top: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --min-height: 0;
}

.tech-location-modal-pin .leaflet-popup-content-wrapper {
  border-radius: 50%;
}

.tech-location-modal-pin .leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button:focus {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button:focus {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-today-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-today-button:focus {
  background-color: #00005c !important;
  color: #ffffff !important;
  border: none !important;
  outline: none !important;
}

ion-modal.blurry-backdrop {
  /* --backdrop-opacity: 0.5; */
  --height: 50%;
  --border-radius: 16px;
  padding: 20px;
}

ion-modal.blurry-backdrop-extended {
  /* --backdrop-opacity: 0.5; */
  --height: 60%;
  --border-radius: 16px;
  padding: 20px;
}

ion-modal.blurry-backdrop-floating {
  --backdrop-opacity: 0.5 !important;
  padding: 8px;
  padding-top: 48px;

  --height: 100%;
  --background: transparent;
}

ion-modal.blurry-backdrop-floating.top {
  align-items: start;
}

ion-modal.blurry-backdrop-floating ion-content {
  --background: transparent;
}

ion-modal.blurry-backdrop-floating ion-toolbar {
  --background: #00005c;
  --color: white;
}

ion-modal.blurry-backdrop-floating::part(content) {
  --border-radius: 16px;
  --background: transparent;
}

ion-modal.blurry-backdrop-auto {
  --backdrop-opacity: 0.5 !important;
  --height: auto;
  --border-radius: 16px;
  padding: 16px;
}

ion-modal.blurry-backdrop-auto-menu {
  --backdrop-opacity: 0.5 !important;
  --border-radius: 16px;
  padding: 16px;
}

ion-modal.video-guide-modal {
  --backdrop-opacity: 0.5 !important;
  --height: auto;
  --border-radius: 16px;
  padding: 16px;
}

ion-modal.video-guide-modal::part(content) {
  background: transparent;
}

.page-above-modal {
  z-index: 10;
}

ion-modal.floating-modal {
  --backdrop-opacity: 0.5 !important;
  --height: auto;
  --border-radius: 16px;
  padding: 20px;
}

ion-modal.blurry-backdrop ion-toolbar {
  --background: #00005c;
  --color: white;
}

@media screen and (min-width: 768px) {
  ion-modal {
    --min-width: 500px;
    --min-height: 60%;
  }
  ion-modal.ai-provider-modal {
    --max-width: 350px;
    --min-height: 60%;
  }
}

@media screen and (min-width: 768px) {
  ion-modal.login-modal {
    --width: 500px;
    --min-width: 500px;
    --min-height: 60%;
  }
}

ion-toast.app-alert-toast::part(icon) {
  color: #00005c;
  font-size: 3em;
}

ion-toast.app-alert-toast::part(button) {
  color: #00005c;
}

ion-toast.app-alert-toast::part(message) {
  font-size: 1.1em;
  font-weight: 500;
}

ion-toast.app-alert-toast::part(header) {
  font-size: 1.4em;
  font-weight: 600;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-dropdown li:hover {
  background-color: #f0f0f0;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  background-color: #fff;
  overflow-y: auto;
  width: 100%;
  max-height: 200px;
  border: 1px solid #ccc;
}

.custom-loader-class {
  --background: transparent;
  --color: #000;
}

.loading-wrapper > .loading-spinner > ion-spinner {
  transform: scale(2.5);
  color: #272626;
}

.button-prompt {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  z-index: 1;
  height: 8px;
  border-radius: 9999px;
  background-color: var(--brand-sunset-orange);
}

.button-prompt::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  z-index: 0;
  border-radius: 9999px;
  background-color: var(--brand-sunset-orange-light);
  animation: pulse 1.5s infinite;
}

ion-popover.popover-prompt::part(content) {
  min-width: 300px;
}
ion-popover.boat-vitals::part(content) {
  left: 0 !important;
  right: 0 !important;
  margin: auto;
}

ion-range {
  --knob-size: 20px;
}

ion-range.ion-range-good::part(bar-active),
ion-range.ion-range-good::part(pin) {
  background: var(--brand-deep-seablue) !important;
}

ion-range.ion-range-danger::part(bar-active),
ion-range.ion-range-danger::part(pin) {
  background: var(--ds-error) !important;
}

ion-range.ion-range-warning::part(bar-active),
ion-range.ion-range-warning::part(pin) {
  background: var(--ds-warning) !important;
  color: #000000 !important;
}

ion-popover.select-popover::part(arrow) {
  display: none;
}

.ngx-tel-input-wrapper > div {
  width: 100%;
}

.tel-input {
  width: 100%;
  outline: none;
}

.ngx-tel-input-wrapper #country-search-box {
  padding: 8px;
}

.ngx-tel-input-wrapper .dropdown-menu {
  border: 0 !important;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  width: 220px !important;
  border-radius: 8px;
}

.bb-input-floating-label-tel:not(.float) {
  padding-left: 100px;
}

.cal-event-item {
  height: 6px;
  border-radius: 9999px;
  width: 6px;
  display: block;
}

.cal-event-item.cal-event-primary {
  background-color: var(--brand-deep-seablue) !important;
}

.cal-event-item.cal-event-secondary {
  background-color: var(--brand-sunset-orange) !important;
}

.cal-event-item-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
}

ion-header.default-header {
  background-color: var(--brand-deep-seablue);
  color: var(--white);
}

ion-header.default-header ion-toolbar {
  --background: var(--brand-deep-seablue);
  --color: var(--white);
}

ion-modal ion-header.default-header ion-toolbar {
  padding-left: 0 !important;
  padding-right: 0 !important;

  --padding-start: 0;
  --padding-end: 0;
}

.max-height-fix {
  height: calc(100vh - 150px);
}

.boxed-layout-container {
  margin: 0 auto;
  max-width: 768px;
  border-style: solid;
  border-color: #28197d;
  border-width: 0 1.5px;
  overflow: visible;
}

.loader-image {
  left: 50%;
  transform: translate(-50%, 0);
}

.marker-label::first-letter {
  color: #ffab03; /* Color the first letter (the star) */
}
.cameraPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.keyboard-active {
  --padding-bottom: 200px;
  padding-bottom: 200px;
  transition: padding-bottom 0.3s ease;
}

.prompt-swiper .swiper-slide {
  width: auto;
}

#map-vendor > .map-adjust {
  height: 64vh !important;
}

.marker-label {
  color: var(--brand-deep-seablue); /* Default text color */
}

.marker-label::first-letter {
  color: #ffab03; /* Color the first letter (the star) */
}
.cameraPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.diy-iframe {
  height: 100%;
  width: 100%;
  /* transform: scaleY(1.2) scaleX(1.2); */
}

.keyboard-active {
  --padding-bottom: 200px;
  padding-bottom: 200px;
  transition: padding-bottom 0.3s ease;
}

.button-pressed,
.button-pressed-plain {
  transition: all 0.3s;
}
.button-pressed:active {
  background-color: #00005c53;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.button-pressed-plain:active {
  /* background-color: #00005c53;
  box-shadow: 0 5px #666; */
  transform: translateY(4px);
}

.mic-pulser {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle,
    rgba(252, 116, 74, 0.5) 100%,
    rgba(252, 116, 74, 0.5) 60%,
    rgba(252, 116, 7, 0.5) 0%
  );
  border-radius: 50%;
  animation: mic-pulser 1.5s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.mic-bouncer {
  animation: mic-bouncer 2s cubic-bezier(0.42, 0, 0.58, 1);
}
@keyframes mic-bouncer {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@keyframes mic-pulser {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

ion-modal.inline-modal-auto {
  --height: fit-content;
  --border-radius: 16px;
}

/* Style the ion-modal content part */
ion-modal.inline-modal-auto::part(content) {
  border-radius: 16px;
  margin: 16px;
  overflow: hidden;
}

ion-refresher {
  z-index: 10;
}



@media screen and (min-width: 768px) {
  ion-modal.ai-alerts-modal::part(content) {
    height: fit-content;
    width: 20%;
    border-radius: 12px;
    min-height: 0px;
  }

  ion-modal.ai-conversation-modal {
    backdrop-filter: blur(2px);
    background: #28252533;
  }

  ion-modal.ai-conversation-modal::part(content) {
    height: 80%;

  }
}
ion-modal.animated-modal {
  --height: auto;
  --max-height: 90%;
  --overflow: hidden;
}

ion-modal.animated-modal::part(content) {
  border-radius: 8px;
  width: 90%;
  position: absolute;
  top: 10px;
  max-height: 90%;
  overflow: auto;
}