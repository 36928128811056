@font-face {
  font-family: "bbicon";
  src: url("/assets/fonts/bbicon/bbicon.eot");
  src: url("/assets/fonts/bbicon/bbicon.eot"),
    url("/assets/fonts/bbicon/bbicon.ttf"),
    url("/assets/fonts/bbicon/bbicon.woff"),
    url("/assets/fonts/bbicon/bbicon.svg");
  src: url("/assets/fonts/bbicon.eot?zfj4gu");
  src: url("/assets/fonts/bbicon.eot?zfj4gu#iefix") format("embedded-opentype"),
    url("/assets/fonts/bbicon.ttf?zfj4gu") format("truetype"),
    url("/assets/fonts/bbicon.woff?zfj4gu") format("woff"),
    url("/assets/fonts/bbicon.svg?zfj4gu#bbicon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bbicon-"],
[class*=" bbicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bbicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bbicon-repair-boat:before {
  content: "\e900";
}
.bbicon-wrench:before {
  content: "\e901";
}
.bbicon-hammer:before {
  content: "\e902";
}
.bbicon-add-circle:before {
  content: "\e903";
}
.bbicon-calendar:before {
  content: "\e904";
}
.bbicon-team:before {
  content: "\e905";
}
.bbicon-invoice:before {
  content: "\e906";
}
.bbicon-customer:before {
  content: "\e907";
}
.bbicon-orders:before {
  content: "\e908";
}
.bbicon-error:before {
  content: "\e909";
}
.bbicon-bell:before {
  content: "\e90a";
}
.bbicon-envelope:before {
  content: "\e90b";
}
.bbicon-key:before {
  content: "\e90c";
}
.bbicon-dropdown:before {
  content: "\e90d";
}
.bbicon-close-circle:before {
  content: "\e90e";
}
.bbicon-search:before {
  content: "\e90f";
}
.bbicon-arrow-left:before {
  content: "\e910";
}
.bbicon-close:before {
  content: "\e911";
}
.bbicon-microphone:before {
  content: "\e912";
}
.bbicon-warn:before {
  content: "\e913";
}
.bbicon-images:before {
  content: "\e914";
}
.bbicon-check:before {
  content: "\e915";
}
.bbicon-map-point:before {
  content: "\e916";
}
.bbicon-message:before {
  content: "\e917";
}
.bbicon-guage:before {
  content: "\e918";
}
.bbicon-parking:before {
  content: "\e919";
}
.bbicon-boat:before {
  content: "\e91a";
}
.bbicon-chevron-right:before {
  content: "\e91b";
}
.bbicon-tools:before {
  content: "\e91c";
}
.bbicon-add:before {
  content: "\e91d";
}
.bbicon-menu:before {
  content: "\e91e";
}
.bbicon-home:before {
  content: "\e91f";
}
.bbicon-settings:before {
  content: "\e920";
}
.bbicon-minus-circle:before {
  content: "\e921";
}
.bbicon-profile-outline:before {
  content: "\e922";
}
.bbicon-lock-outline:before {
  content: "\e923";
}
.bbicon-chevron-up:before {
  content: "\e924";
}
.bbicon-email-alt-outline:before {
  content: "\e925";
}
.bbicon-chevron-left:before {
  content: "\e926";
}
.bbicon-chevron-down:before {
  content: "\e927";
}
.bbicon-anchor:before {
  content: "\e928";
}
.bbicon-phone-outline:before {
  content: "\e929";
}
.bbicon-people-outline:before {
  content: "\e92a";
}
.bbicon-money:before {
  content: "\e92b";
}
.bbicon-people:before {
  content: "\e92c";
}
.bbicon-logout:before {
  content: "\e92d";
}
.bbicon-info:before {
  content: "\e92e";
}
.bbicon-info-outline:before {
  content: "\e92f";
}
.bbicon-filter-list:before {
  content: "\e930";
}
.bbicon-lock:before {
  content: "\e931";
}
.bbicon-credit-card:before {
  content: "\e932";
}
.bbicon-history:before {
  content: "\e933";
}
.bbicon-delete-outline:before {
  content: "\e934";
}
.bbicon-captain-ai:before {
  content: "\e935";
}
.bbicon-proposal:before {
  content: "\e936";
}
.bbicon-my-location:before {
  content: "\e937";
}
.bbicon-camera:before {
  content: "\e938";
}
.bbicon-send-message:before {
  content: "\e939";
}
.bbicon-pen:before {
  content: "\e93a";
}
.bbicon-reload:before {
  content: "\e93b";
}
.bbicon-phone:before {
  content: "\e93c";
}
.bbicon-business:before {
  content: "\e93d";
}
.bbicon-list-bulleted:before {
  content: "\e93e";
}
.bbicon-check-all:before {
  content: "\e93f";
}
.bbicon-key1:before {
  content: "\e940";
}
.bbicon-update:before {
  content: "\e941";
}
.bbicon-block:before {
  content: "\e942";
}
.bbicon-comment:before {
  content: "\e943";
}
.bbicon-check-circle:before {
  content: "\e944";
}
.bbicon-clock:before {
  content: "\e945";
}
.bbicon-cloud:before {
  content: "\e946";
}
.bbicon-mobile-phone:before {
  content: "\e947";
}
.bbicon-wifi:before {
  content: "\e948";
}
.bbicon-engine:before {
  content: "\e949";
}
.bbicon-generator:before {
  content: "\e94a";
}
.bbicon-stabilizer:before {
  content: "\e94b";
}
.bbicon-watermaker:before {
  content: "\e94c";
}
.bbicon-question:before {
  content: "\e94d";
}
.bbicon-package:before {
  content: "\e94e";
}
.bbicon-add-boat:before {
  content: "\e94f";
}
.bbicon-add-camera:before {
  content: "\e950";
}
.bbicon-add-images:before {
  content: "\e951";
}
.bbicon-add-tools:before {
  content: "\e952";
}
.bbicon-loading:before {
  content: "\e953";
}
.bbicon-cleaning-polishing:before {
  content: "\e954";
}
.bbicon-electronics:before {
  content: "\e955";
}
.bbicon-engine-room-mechanical:before {
  content: "\e956";
}
.bbicon-engine-service:before {
  content: "\e957";
}
.bbicon-fuel:before {
  content: "\e958";
}
.bbicon-general-service:before {
  content: "\e959";
}
.bbicon-hull-dive-service:before {
  content: "\e95a";
}
.bbicon-provisioning:before {
  content: "\e95b";
}
.bbicon-business-outline:before {
  content: "\e95c";
}
.bbicon-arrow-right:before {
  content: "\e95d";
}
.bbicon-lightbulb:before {
  content: "\e95e";
}
.bbicon-stop-circle:before {
  content: "\e95f";
}
.bbicon-longitude:before {
  content: "\e960";
}
.bbicon-latitude:before {
  content: "\e961";
}
.bbicon-status-doing:before {
  content: "\e962";
}
.bbicon-status-blocked:before {
  content: "\e963";
}
.bbicon-status-open:before {
  content: "\e964";
}
.bbicon-status-completed:before {
  content: "\e965";
}
.bbicon-add-circle-outline:before {
  content: "\e966";
}
.bbicon-paper-clip:before {
  content: "\e967";
}
.bbicon-shield:before {
  content: "\e968";
}
.bbicon-eye-off:before {
  content: "\e969";
}
.bbicon-eye:before {
  content: "\e96a";
}
.bbicon-drag-handle:before {
  content: "\e96b";
}
.bbicon-drag-indicator:before {
  content: "\e96c";
}
.bbicon-videocam:before {
  content: "\e96d";
}
.bbicon-packages:before {
  content: "\e96e";
}
.bbicon-content-copy:before {
  content: "\e96f";
}
.bbicon-preferences:before {
  content: "\e970";
}
.bbicon-drop:before {
  content: "\e971";
}
.bbicon-boat-crew:before {
  content: "\e972";
}
.bbicon-car:before {
  content: "\e973";
}
.bbicon-more-vert:before {
  content: "\e974";
}
.bbicon-more-horiz:before {
  content: "\e975";
}
.bbicon-pause-circle:before {
  content: "\e976";
}
.bbicon-envelope-outline:before {
  content: "\e977";
}
.bbicon-upload-file:before {
  content: "\e978";
}
.bbicon-arrow-down:before {
  content: "\e979";
}
.bbicon-filter-list-alt:before {
  content: "\e97a";
}
.bbicon-book:before {
  content: "\e97b";
}
.bbicon-clipboard:before {
  content: "\e97c";
}
.bbicon-diving:before {
  content: "\e97d";
}
.bbicon-exclamation:before {
  content: "\e97e";
}
.bbicon-search-circle:before {
  content: "\e97f";
}
.bbicon-spark:before {
  content: "\e980";
}
.bbicon-sparks:before {
  content: "\e981";
}
.bbicon-thumb-down:before {
  content: "\e982";
}
.bbicon-thumb-up:before {
  content: "\e983";
}
.bbicon-cellular:before {
  content: "\e984";
}
.bbicon-vendor:before {
  content: "\e985";
}
.bbicon-weather:before {
  content: "\e986";
}
.bbicon-external-link:before {
  content: "\e987";
}
